// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { Icon } from 'react-components/media';
import type { TemplateProps } from 'types/template';
import { Type } from 'react-components/typography';
import { color, media } from 'react-components/styles';
import { theme } from 'styled-tools';
import BubblesLeftSvg from 'assets/svg/bubbles-left.svg';
import BubblesRightSvg from 'assets/svg/bubbles-right.svg';
import Footer from 'components/footer';
import Metatags from 'components/metatags';
import Navbar from 'components/navbar';
import PageContainer from 'components/page-container';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import Separator from 'components/core/separator';
import SubscriptionForm from 'components/core/subscription-form';
import styled from 'styled-components';

/**
 * Custom marker.
 */

const customMarker = `
  <svg viewBox="0 -30 80 110" height="1em" with="1em" xmlns="http://www.w3.org/2000/svg">
    <path fill="#39D9B9" d="M3.053 24.276A39.5 39.5 0 0139.656 0 39.403 39.403 0 0179 39.5 39.5 39.5 0 113.053 24.276zm46.175 11.196a10.33 10.33 0 00-9.572-6.35c-5.694.012-10.3 4.637-10.291 10.33a10.33 10.33 0 1019.863-3.98z" />
  </svg>
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background: linear-gradient(-180deg, ${color('blue900')} 0%, ${color('blue800')} 60vh, ${color('blue800')} 100%);
  color: ${color('white')};
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: grid;
  grid-gap: 3rem;
  min-height: 100vh;
  padding-top: 8rem;

  ${media.min('sm')`
    grid-gap: 5rem;
    margin: 0 auto 5rem;
    max-width: 60vw;
    padding-top: 10rem;
  `}

  ${media.min('lg')`
    margin-bottom: 10rem;
    max-width: 50vw;
    padding-top: 12rem;
  `}
`;

/**
 * `BubblesLeftIcon` styled component.
 */

const BubblesLeftIcon = styled(Icon).attrs({
  icon: BubblesLeftSvg,
  size: '35%'
})`
  left: -8%;
  position: absolute;
  top: 0;

  ${media.min('lg')`
    top: -18%;
  `}
`;

/**
 * `BubblesRightIcon` styled component.
 */

const BubblesRightIcon = styled(Icon).attrs({
  icon: BubblesRightSvg,
  size: '25%'
})`
  position: absolute;
  right: -6%;
  top: 8%;
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  p,
  small {
    margin: 0 0 1rem;
  }

  h1,
  h2 {
    color: ${color('green500')};
    font-weight: 500;
  }

  h1 {
    ${theme('typography.styles.h2')}
  }

  h2 {
    ${theme('typography.styles.h3')}
  }

  h3 {
    ${theme('typography.styles.h4')}
  }

  h4 {
    ${theme('typography.styles.h5')}
  }

  blockquote {
    display: block;
    margin: 0 0 0 3rem;
    position: relative;

    &::before {
      background-color: ${color('green500')};
      border-radius: 2px;
      bottom: 0;
      content: '';
      left: -1rem;
      position: absolute;
      top: 0;
      width: 4px;
    }
  }

  a {
    color: ${color('green500')};
    transition: color ${theme('transitions.default')};

    &:focus,
    &:hover {
      color: ${color('green300')};
    }
  }

  ol {
    list-style: lower-alpha;
    margin-bottom: 3rem;

    > li {
      margin-bottom: 1.5rem;

      &::marker {
        color: ${color('green500')};
      }
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 2rem;

    & > li {
      list-style-type: disc;
      margin-bottom: 1.5rem;
      padding-inline-start: 1ch;

      @-moz-document url-prefix() {
        padding-inline-start: 2ch;
      }

      &::marker {
        content: url('data:image/svg+xml;utf8,${encodeURIComponent(customMarker)}');
      }
    }
  }
`;

/**
 * `Richtext` page.
 */

function Richtext({ pageContext }: TemplateProps): Node {
  const { content, lead, title } = pageContext?.content;

  return (
    <PageContainer>
      <Wrapper>
        <Metatags {...pageContext?.metatags} />

        <Navbar />

        <Container>
          <BubblesLeftIcon />

          <BubblesRightIcon />

          <Content>
            <Type.H1
              fontWeight={600}
              textAlign={'center'}
            >
              {title}
            </Type.H1>

            <StyledRawHtml>
              {lead}
            </StyledRawHtml>

            <StyledRawHtml>
              {content}
            </StyledRawHtml>
          </Content>
        </Container>

        <Container>
          <SubscriptionForm />

          <Separator />
        </Container>

        <Footer />
      </Wrapper>
    </PageContainer>
  );
}

/**
 * Export `Richtext` page
 */

export default Richtext;
